@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaORs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaHRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaMRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaNRs7nczIHNHI.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXX3I6Li01BKofIMNaDRs7nczIH.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofINeaBTMnFcQ.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofINeaBTMnFcQ.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofINeaBTMnFcQ.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofINeaBTMnFcQ.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofINeaBTMnFcQ.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofINeaBTMnFcQ.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    src: url(../../assets/fonts/XRXV3I6Li01BKofINeaBTMnFcQ.woff2?h=601f1de2bd152c1a5b65c8cadc1d6853) format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

.logo-index {
    width: 90%
}

#seor-logo {
    padding-top: 50px
}

#hr-margin {
    padding-top: 20px
}

#accordionSidebar {
    padding-top: 60px;
    padding-bottom: 30px
}

#dash-btn {
    margin-top: 30px
}

.input-ds-index {
    background-color: #1079be
}

.btn-user,
.input-ds-index:hover {
    background-color: #60b080
}

.space-icone {
    padding-right: 15px
}

#exampleInputEmail {
    margin-top: 20px;
    margin-bottom: 20px
}

.btn-user:hover,
.gb-inputs {
    background-color: #14aee4
}

.rows-stl {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px
}

.row-prs-ds {
    margin-bottom: 15px
}

.bg-gradient-primary {
    background-color: #fff;
    background-image: linear-gradient(180deg, #14aee4 20%, #1079be 70%);
}

.bg-gradient-primary-login{
    background-color: #fff;
    background-image: linear-gradient(180deg, #14aee4 20%, #1079be 70%);

}

.img-login {
    width: 100%
}

.bg-login-image{
    background-size: cover 
}

#col-ds {
    padding: 0
}

.logo-login {
    width: 50%
}

#text-login {
    margin-top: 30px;
    font-size: 30px
}

.input-ds {
    background-color: #fff
}

.span-arabic-text {
    position: relative;
    top: 90%;
    left: 30%;
    font-size: 18px
}

.lab-ps-dc,
.label-ds {
    margin-right: 15px
}

.rows-ds {
    margin-top: 6px;
    margin-bottom: 6px
}

.card-pad-ds {
    margin-top: 20px
}

.redio-sp-ds {
    margin-top: 10px;
    margin-right: 20px
}

.div-cont {
    margin-top: 40px
}

.footer-ds{
   bottom: 0;
   color: #1079be;
   width: 100%;
   z-index: -1;
   padding-bottom: 15px;
}

.footer-ds-lg{
   color: #282828;
   width: 100%;
   z-index: -1;
}
