/* arabic fonts*/
@import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');

*{
    font-size: 14px;
    font-weight: 300;
}

/* Badge color */
.badge-success{
    background-color: #00AA10;
    padding: 5px;
    border-radius: 5px;
    margin: 8px;
    color: #fff;
}

.badge-success-2-color{
    background-color: #00AA10;
    color: #fff;
}

.badge-highlight{
    background-color: #F5C41D;
    padding: 5px;
    border-radius: 5px;
    margin: 8px;
    color: #fff;
}

.badge-highlight-2-color{
    background-color: #F5C41D;
    color: #fff;
}

.badge-info{
    background-color: #2F73B6;
    padding: 5px;
    border-radius: 5px;
    margin: 8px;
    color: #fff;
}

.badge-info-2-color{
    background-color: #2F73B6;
    color: #fff;
}

.badge-alert{

    background-color: #E00B19;
    padding: 5px;
    border-radius: 5px;
    margin: 8px;
    color: #fff;
}

.badge-alert-2-color{
    background-color: #E00B19;
    color: #fff;
}

.badge-gray{
    background-color: #d8d8d8;
    padding: 5px;
    border-radius: 5px;
    margin: 8px;
    color: #fff;
}

.badge-gray-2-color{
    background-color: #d8d8d8;
    color: #fff;
}

/* Badge color ----- */

.font-text-paragraphe{
    font-size: 14px;
    font-weight: 300;
}

.font-text-paragraphe-bg{
    font-size: 14px;
    font-weight: 300;
}


.font-text-title{
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    color: #26AAE0;
}

.btn-ds{
    width: 100%;
}

.icon-btn-ds{
    margin-left: 10px;
}

.table-title{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #26AAE0;
}

.icon-dashboard-color{
    color: #cbcbcb;
}

.ds-card-dash{
    border: none;
}

.mt-ds{
    margin-top: 70px;
}

.mt-ds-ags{
    margin-top: 30px;
}

.color-rs{
    color: #26AAE0;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
}

.ds-text-login-tb a{
    color: #26AAE0;
}

.text-bv{
    margin-bottom: 60px;
}

.arabic-text-login{
    font-size: 20px;
    font-family: 'Tajawal', sans-serif;
    background-color: rgb(47, 115, 182, 0.7);
    border-radius: 10px;
    padding: 8px;
}

.size-btn-search{
    width: 100%;
}

.bdg-pd-ds{
    margin-left: 2px;
}

.img-avatar-cat{
    width: 160px;
    height: 160px;
    background-image: url("../../images/avatar-temp.png");
    background-size: cover;
    
}

.align-horis-contain{
margin: auto;
  width: 50%;

}

.sub-container-reseau-aep{
    background-color: #f4f4f4;
    border-radius: 10px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    
}

.switch-ds-container{
    margin-top: 10px;
}

/* switch costume --- */

.form-switch .form-check-input[type=checkbox] {
    border-radius: 2em;
    height: 20px;
    width: 50px;
}



.form-switch .form-check-input:focus {
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}
.form-switch .form-check-input:checked {
    background-color: #26AAE0;
    border-color: #26AAE0;
    border: none;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

/* switch costume --- */
.pad-printer{
    padding: 50px;
}

.table{ 
    overflow: hidden; /* Hide scrollbars */
}

.page-link{
    color: #282828;
}

.text-pagination{
    margin-left: 15px;
}

.form-control {
    font-size: 14px;
    font-weight: 300;
}



/*Print style begin*/
.print-page-style{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 45px;
    padding-right: 45px;
}

.titre-print{
    font-weight: 500;
    text-transform: uppercase;
}

.logo-print{
    width: 35%;
}

.footer-print{
    text-align: right;
    color: #2F73B6;
    text-decoration:overline;
}

.btn-print{
    background-color: #26AAE0;
    color: #fff;
}

.layout-print-btn{
    text-align: right;
}

.show-search-data{
    background-color: #F0F1F2;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
    padding: 5px;
    padding-left: 7px;
    border: 0.1px solid #d7d7d7;
    width:100%;
    height: 100%;
}
/*Print style end*/
