/* Side menu CSS style */

.side-menu {
    position: fixed;
    background-color: #fff;
    background-image: linear-gradient(180deg, #14aee4 20%, #1079be 70%);
    width: 260px;
    height: 100%;
    box-sizing: border-box;
    padding: 25px 15px;
    transition: width .2s ease-in;
}

.side-menu.inactive{
    width: 65px;
}

.side-menu .top-section {
    position: relative;
}

.side-menu .top-section .logo {
    width: 50%;
    overflow: hidden;
    display: inline-block;
}

.side-menu .top-section .logo img {
    max-width: 100%;
}

/* BTN raha sgira */
.side-menu .top-section .toggle-menu-btn {
    color: #FFF;
    /*font-size: 25px;*/
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);   
}

.side-menu .top-section .size-btn{
    font-size: 25px;
}


.side-menu.inactive .top-section .toggle-menu-btn {
    right: -50px;
    color: #14aee4;
}

.side-menu .search-controller .search-btn{
    width: 40px;
    height: 35px;
    position: absolute;
    background: transparent;
    border: 0;
    font-size: 14px;
    color: #14aee4;
}

.side-menu .search-controller input[type=text]{
    border: 0;
    outline: none;
    height: 35px;
    background: #FFF;
    border-radius: 10px;
    display: block;
    margin: 20px 0 ;
    width: 100%;
    box-sizing: border-box;
    padding-left:40px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
}

.side-menu .main-menu .menu-item{
    color: #FFF;
    text-decoration: none;
    font-size: 13px;
    display: block;
    font-weight: 300;
    cursor: pointer;
    margin-top: 14px;
    width: 110%;

}


.side-menu .main-menu .menu-item.active{
    background: #fff;
    width: 165%;
    color: #1079be;
    border-radius: 10px;
}

.side-menu .main-menu .menu-item .menu-icon {
    display: inline-block;
    width: 35px;
    height: 35px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    margin-right: 12px;
}

.side-menu .main-menu .menu-item span{
    position: absolute;
    display: inline-block;
    line-height: 35px;
    opacity: 1;
    transition: opacity .2s ease-in;
}

.side-menu.inactive .main-menu .menu-item span{
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}

.side-menu .main-menu {
    margin: 5px 0;
}

.side-menu .main-menu .sub-menu{
    color: #FFF;
    margin-left: 20px;
    border-left: 1px solid #FFF;
    box-sizing: border-box;
    padding-left: 30px;
    max-height: 0;
    width: 105%;
    overflow: hidden;
    transition: max-height .2s ease-in;
}


.side-menu .main-menu .sub-menu.active{
    max-height: 200px;
}

.side-menu .main-menu .sub-menu a{
    display: block;
    margin: 5px 0;
    font-size: 14px;
    font-weight: 300;
    color: #FFF;
    display: block;
    box-sizing: border-box;
    padding: 5px 0;
    padding-left: 7px;
    text-decoration: none;
}

.side-menu .main-menu .sub-menu a.active{
    background: #FFF;
    color: #1079be;
    border-radius: 10px;
    display: block;
    box-sizing: border-box;
    padding: 5px 0;
    padding-left: 7px;
    text-decoration: none;
}

.side-menu-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.side-menu-footer .user-info{
    margin: 0 20px;
    color: #FFF;
    opacity: 1;
    transition: opacity .2s ease-in;
}

.side-menu.inactive .side-menu-footer .user-info{
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}

.side-menu-footer .user-info h5{
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 300;
}

.side-menu-footer .user-info p{
    font-size: 12px;
    font-weight: 300;
}


.divider{
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #FFF;
}
