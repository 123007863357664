@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300&display=swap');

* {
    margin: O;
    padding: O;
}

ul{
    margin: 0;
    padding: 0;
}

li{
    list-style: none;
}

html,body{
    /* wihte space
    width: 100%;
    height: 100%;
    margin: O;
    padding: O;
    overflow-x: hidden;
*/

    background-color: rgb(255, 255, 255);
    font-family: 'Open Sans', sans-serif;
}

.container{
    /*margin-left: 260px;*/
    /*transition: margin-left .2s ease-in;*/
    padding-top: 20px;
    padding-left: 30px;
}

.container.inactive{
    margin-left: -155px;
    overflow-x: hidden;
    overflow-y: auto;
}
